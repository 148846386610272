import React from 'react';
import { Form, Col, Image } from 'react-bootstrap';

export default function ImageAddAndPreview({
  imagePreview,
  onChange,
  imageInputRef,
  ColSize,
  Label
}) {
  return (
    <>
      <>
        <Col
          md={ColSize ? ColSize : 4}
          sm={ColSize ? ColSize : 4}
          lg={ColSize ? ColSize : 4}
        >
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>{Label}:</Form.Label>
            <Form.Control
              type="file"
              onChange={onChange}
              ref={imageInputRef}
            />
          </Form.Group>
        </Col>
        {imagePreview  && (
          <Col xs={6} md={ColSize ? ColSize - 12 : 4}>
            <Image src={imagePreview} thumbnail />
          </Col>
        )}
      </>
    </>
  );
}

// How to use
// const MyComponent = () => {
  // const imageInputRef = useRef(null);
  // const [selectedImage, setSelectedImage] = useState(null);
  // const [imagePreview, setImagePreview] = useState(null);

// const handleImageChange = (event) => {
//   setSelectedImage(event.target.files[0]);
//   const reader = new FileReader();
//   reader.onloadend = () => {
//     setImagePreview(reader.result);
//   };
//   reader.readAsDataURL(event.target.files[0]);
// };

// const reset = () => {
//   setSelectedImage(null);
//   setImagePreview(null);
//   if (imageInputRef.current) {
//     imageInputRef.current.value = null;
//   }
//   setFormData(emptyGallery);
// };

//   return (
//   <ImageAddAndPreview
//   imagePreview={imagePreview}
//   onChange={handleImageChange}
//   imageInputRef={imageInputRef}
//   Label = "Image"
// />
//   );
// };

// export default MyComponent;