// import "./App.css";
// import { useState, useEffect } from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { useLoadingContext } from "./Loading/LoadingContext";
// import Login from "./Pages/Login/Login";
// import { Card, CardFooter } from "react-bootstrap";
// import Logo from "../src/assets/logo.png";
// import Logo2 from "../src/assets/image.png";
// import Header from "./Components/Header/Header";
// import Loader from "./Components/Common/Loader ";
// import api from "./Utils/api";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import RoutesComponent from "./RoutesComponent";
// import Logout from "./Pages/Logout/Logout";

// function App() {
//   const [userName, setUserName] = useState("");
//   let loginToken = localStorage.getItem("token");
//   const { isLoading, setIsLoading } = useLoadingContext();

//   useEffect(() => {
//     if (loginToken) {
//       setIsLoading(true);
//       debugger;
//       CheckIsTokenExpired()
//         .then((IsTokenNotExpired) => {
//           if (IsTokenNotExpired) {
//             const storedUserName = localStorage.getItem("userName");
//             if (storedUserName) {
//               setUserName(storedUserName);
//             }
//           } else {
//             window.location.href = "/logout";
//           }
//         })
//         .catch((error) => {
//           console.error("Error checking token expiration:", error);
//         })
//         .finally(() => {
//           setIsLoading(false);
//         });
//     }
//   }, [loginToken]);

//   const CheckIsTokenExpired = () => {
//     return api
//       .post(`Login/IsTokenExpired?token=${loginToken}`)
//       .then((result) => {
//         return result.data.data;
//       })
//       .catch((error) => {
//         throw error; // Rethrow the error to be caught in the useEffect
//       });
//   };

//   return (
//     <BrowserRouter>
//       <div className="App h-screen overflow-hidden">
//         <ToastContainer />
//         {isLoading && <Loader />}
//         {loginToken ? (
//           <>
//             <div
//               className="flex h-full overflow-hidden"
//               style={{ height: "calc(100vh - 2px)" }}
//             >
//               <Card className="NavbarSideCardCss">
//                 <Card.Header className="NavbarSideCardHeaderCss">
//                   <img src={Logo} alt="" className=" FullLogo" />
//                   <img src={Logo2} alt="" className=" OnlyTLogo" />
//                 </Card.Header>
//                 <Card.Body className="overflow-y-auto p-0 scrollable-content">
//                   <Header />
//                 </Card.Body>
//                 <Card.Footer className=" NavbarSideCardFooterCss">
//                   <a href="/logout">
//                     <i className="fas fa-sign-out-alt"></i> <span>LogOut</span>
//                   </a>
//                 </Card.Footer>
//               </Card>

//               <Card className="ContentSideCardCss">
//                 <Card.Body className="overflow-y-auto p-0.5 overflow-x-hidden scrollable-content">
//                   <RoutesComponent />
//                 </Card.Body>
//                 <CardFooter>
//                   <span>
//                     <strong>Design And Developed By</strong> : TAPTI TECHNOLOGY.
//                     All rights reserved.
//                   </span>
//                 </CardFooter>
//               </Card>
//             </div>
//           </>
//         ) : (
//           <Login />
//         )}
//       </div>
//     </BrowserRouter>
//   );
// }

// export default App;
import "./App.css";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route,Link } from "react-router-dom";
import { useLoadingContext } from "./Loading/LoadingContext";
import Login from "./Pages/Login/Login";
import { Card, CardFooter } from "react-bootstrap";
import Logo from "../src/assets/logo.png";
import Logo2 from "../src/assets/image.png";
import Header from "./Components/Header/Header";
import Loader from "./Components/Common/Loader ";
import api from "./Utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoutesComponent from "./RoutesComponent";
import Logout from "./Pages/Logout/Logout";

function App() {
  const [userName, setUserName] = useState("");
  let loginToken = localStorage.getItem("token");
  const { isLoading, setIsLoading } = useLoadingContext();

  useEffect(() => {
    if (loginToken) {
      setIsLoading(true);
      debugger;
      CheckIsTokenExpired()
        .then((IsTokenNotExpired) => {
          if (IsTokenNotExpired) {
            const storedUserName = localStorage.getItem("userName");
            if (storedUserName) {
              setUserName(storedUserName);
            }
          } else {
            window.location.href = "/logout";
          }
        })
        .catch((error) => {
          console.error("Error checking token expiration:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [loginToken]);

  const CheckIsTokenExpired = () => {
    return api
      .post(`Login/IsTokenExpired?token=${loginToken}`)
      .then((result) => {
        return result.data.data;
      })
      .catch((error) => {
        throw error; // Rethrow the error to be caught in the useEffect
      });
  };

  return (
    <BrowserRouter>
      <div className="App h-screen overflow-hidden">
        <ToastContainer />
        {isLoading && <Loader />}
        {loginToken ? (
          <>
            <div
              className="flex h-full overflow-hidden"
              style={{ height: "calc(100vh - 2px)" }}
            >
              <Card className="NavbarSideCardCss">
                <Card.Header className="NavbarSideCardHeaderCss">
                  <img src={Logo} alt="" className="FullLogo" />
                  <img src={Logo2} alt="" className="OnlyTLogo" />
                </Card.Header>
                <Card.Body className="overflow-y-auto p-0 scrollable-content">
                  <Header />
                </Card.Body>
                <Card.Footer className="NavbarSideCardFooterCss">
                  <Link to="/logout">
                    <i className="fas fa-sign-out-alt"></i> <span>LogOut</span>
                  </Link>
                </Card.Footer>
              </Card>

              <Card className="ContentSideCardCss">
                <Card.Body className="overflow-y-auto p-0.5 overflow-x-hidden scrollable-content">
                  <RoutesComponent />
                </Card.Body>
                <CardFooter>
                  <span>
                    <strong>Design And Developed By</strong> : TAPTI TECHNOLOGY.
                    All rights reserved.
                  </span>
                </CardFooter>
              </Card>
            </div>
          </>
        ) : (
          <Login />
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
