import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputGroupWithIcon from "../../Components/Common/InputGroupWithIcon ";
import SubmitButtonOfForm from "../../Components/Common/SubmitButtonOfForm";
import GlobalFormTagWithSubmit from "../../Components/Common/GlobalFormTagWithSubmit";
import ImageAddAndPreview from "../../Components/Common/ImageAddAndPreview";
import UserSetting from "../../UserSettings.json";

const emptyProductCategory = {
  ProductCategoryID: 0,
  ProductCategoryName: "",
};

export default function ProductCategoryComponent({
  editedProductCategoryData,
  returnID,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const ProductCategoryFormRef = useRef(null);
  const imageInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState(emptyProductCategory);

  useEffect(() => {
    if (editedProductCategoryData) {
      setFormData({
        ProductCategoryID: editedProductCategoryData.productCategoryID,
        ProductCategoryName: editedProductCategoryData.productCategoryName,
      });
      setImagePreview(UserSetting.imgURL + editedProductCategoryData.imagePath);
    }
  }, [editedProductCategoryData]);

  useEffect(() => {
    if (clearData == false) {
      reset();
    }
  }, [clearData]);

  const handleInputChange = (ProductCategory) => {
    const { name, value, type, checked } = ProductCategory.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  // Insert & Update Data
  const insertProductCategoryMaster = (ProductCategory) => {
    setValidated(true);
    const form = ProductCategory.currentTarget;
    if (form.checkValidity() === false) {
      ProductCategory.stopPropagation();
      return;
    } else {
      const Data = {
        ProductCategoryID: formData?.ProductCategoryID || 0,
        ProductCategoryName: formData.ProductCategoryName,
      };

      const form_Data = new FormData();
      form_Data.append("ProductCategoryID", formData?.ProductCategoryID || 0);
      form_Data.append("ProductCategoryName", formData.ProductCategoryName);
      form_Data.append("image", selectedImage);

      const endpoint =
        formData.ProductCategoryID === 0
          ? "ProductCategory/InsertProductCategory"
          : "ProductCategory/EditProductCategory";

      api[formData.ProductCategoryID === 0 ? "post" : "patch"](
        endpoint,
        form_Data
      )
        .then((result) => {
          if (result.status === 200) {
            setValidated(false);
            toast.success(result.data.message);
            if (returnID) {
              returnID(result.data.data);
            }
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          reset();
          onClose();
        });
    }
  };

  const reset = () => {
    setFormData(emptyProductCategory);
    setSelectedImage(null);
    setImagePreview(null);
    if (imageInputRef.current) {
      imageInputRef.current.value = null;
    }
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <GlobalFormTagWithSubmit
      validated={validated}
      onSubmit={insertProductCategoryMaster}
      refName={ProductCategoryFormRef}
      formName="ProductCategoryForm"
    >
      <Row>
        <InputGroupWithIcon
          ColSize={8}
          icon="fas fa-building"
          type="text"
          name="ProductCategoryName"
          placeholder="Enter ProductCategory Name"
          value={formData.ProductCategoryName}
          onChange={handleInputChange}
          required
        />
      </Row>
      <Row>
        <ImageAddAndPreview
          imagePreview={imagePreview}
          onChange={handleImageChange}
          imageInputRef={imageInputRef}
          Label="Image"
        />
      </Row>

      <SubmitButtonOfForm HeadID={formData?.ProductCategoryID} />
    </GlobalFormTagWithSubmit>
  );
}
