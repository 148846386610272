import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, InputGroup, Button, Image } from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputGroupWithIcon from "../../Components/Common/InputGroupWithIcon ";
import CKEditorInput from "../../Components/Common/CKEditorInput";
import SubmitButtonOfForm from "../../Components/Common/SubmitButtonOfForm";
import GlobalFormTagWithSubmit from "../../Components/Common/GlobalFormTagWithSubmit";
import GlobalCheckbox from "../../Components/Common/GlobalCheckbox";
import { isAction } from "@reduxjs/toolkit";

const emptyUserMaster = {
  User_ID: 0,
  UserName: "",
  FullName: "",
  MobileNumber: "",
  Password: "",
  isActive: true,
};

export default function UserMasterComponent({
  editedUserMasterData,
  returnID,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyUserMaster);
  const UserMasterFormRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    debugger;
    if (editedUserMasterData) {
      setFormData({
        User_ID: editedUserMasterData.userID,
        UserName: editedUserMasterData.userName,
        FullName: editedUserMasterData.fullName,
        MobileNumber: editedUserMasterData.mobileNumber,
        Password: editedUserMasterData.password,
        isActive: editedUserMasterData.isActive,
      });
    }
  }, [editedUserMasterData]);

  useEffect(() => {
    if (clearData == false) {
      reset();
    }
  }, [clearData]);

  const handleInputChange = (UserMaster) => {
    const { name, value, type, checked } = UserMaster.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  // Insert & Update Data
  const insertUserMasterMaster = (UserMaster) => {
    setValidated(true);

    const form = UserMaster.currentTarget;
    if (form.checkValidity() === false) {
      UserMaster.stopPropagation();
      return;
    } else {
      const Data = {
        UserID: formData?.User_ID,
        UserName: formData.UserName,
        FullName: formData.FullName,
        MobileNumber: formData.MobileNumber,
        Password: formData.Password,
        IsActive: formData.isActive,
      };

      const endpoint =
        formData.User_ID === 0
          ? "UserMaster/InsertUserMaster"
          : "UserMaster/EditUserMaster";

      api[formData.User_ID === 0 ? "post" : "patch"](endpoint, Data)
        .then((result) => {
          if (result.status === 200) {
            setValidated(false);
            toast.success(result.data.message);
            if (returnID) {
              returnID(result.data.data);
            }
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          reset();
          onClose();
        });
    }
  };

  const reset = () => {
    setFormData(emptyUserMaster);
  };

  return (
    <>
      <GlobalFormTagWithSubmit
        validated={validated}
        onSubmit={insertUserMasterMaster}
        refName={UserMasterFormRef}
        formName="UserMasterForm"
      >
        <Row>
          <InputGroupWithIcon
            ColSize={8}
            icon="fas fa-building"
            type="text"
            name="UserName"
            placeholder="Enter User Name"
            value={formData.UserName}
            onChange={handleInputChange}
            required
          />
        </Row>
        <Row>
          <InputGroupWithIcon
            ColSize={8}
            icon="fas fa-building"
            type="text"
            name="FullName"
            placeholder="Enter Full Name"
            value={formData.FullName}
            onChange={handleInputChange}
          />
        </Row>
        <Row>
          <InputGroupWithIcon
            ColSize={8}
            icon="fas fa-building"
            type="number"
            name="MobileNumber"
            placeholder="Enter Mobile Number"
            value={formData.MobileNumber}
            onChange={handleInputChange}
            required
          />
        </Row>
        <Row>
          <InputGroupWithIcon
            ColSize={8}
            icon="fas fa-building"
            type={showPassword ? "text" : "password"}
            name="Password"
            placeholder="Enter Password"
            value={formData.Password}
            onChange={handleInputChange}
            required
          />
          <Col md={3} style={{ alignItems: "Center",fontSize:"smaller" ,display:"flex"}}>
              <lord-icon
                src="https://cdn.lordicon.com/ccrgnftl.json"
                trigger="hover"
                style={{ width: "40px", height: "40px" }}
                onClick={togglePasswordVisibility}
                state={showPassword ? undefined : "hover-cross"}
              />
              <p>{`${showPassword ? "Hide" : "Show"} Password`} </p>
          </Col>
        </Row>
        <Row>
          <GlobalCheckbox
            label="is Active"
            name="isActive"
            checked={formData.isActive}
            onChange={handleInputChange}
          />
        </Row>

        <SubmitButtonOfForm HeadID={formData?.User_ID} />
      </GlobalFormTagWithSubmit>
    </>
  );
}
