import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, InputGroup, Button, Image } from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputGroupWithIcon from "../../Components/Common/InputGroupWithIcon ";
import CKEditorInput from "../../Components/Common/CKEditorInput";
import SubmitButtonOfForm from "../../Components/Common/SubmitButtonOfForm";
import GlobalFormTagWithSubmit from "../../Components/Common/GlobalFormTagWithSubmit";
import ProductCategoryComponent from "../ProductCategory/ProductCategoryComponent";
import ImageAddAndPreview from "../../Components/Common/ImageAddAndPreview";
import UserSetting from "../../UserSettings.json";
import GlobalDDComponent from "../../Components/Common/GlobalDDComponent";
import GlobalCheckbox from "../../Components/Common/GlobalCheckbox";


const emptyEvent = {
  ProductID: 0,
  ProductName: "",
  ProductCategoryID: 0,
  IsActive: true,
  IsHomePage: false,
  Details: "",
};

export default function ProductComponent({
  editedProductData,
  returnID,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const ProductFormRef = useRef(null);
  const [formData, setFormData] = useState(emptyEvent);
  const [details, setDetails] = useState("");
  const [ckValidated, setCkValidated] = useState(false);
  const imageInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [ddValidationError, setDDValidationError] = useState(false);

  useEffect(() => {
    if (editedProductData) {
      setFormData({
        ProductID: editedProductData.productID,
        ProductName: editedProductData.productName,
        ProductCategoryID: editedProductData.productCategoryID,
        IsActive: editedProductData.isActive,
        IsHomePage: editedProductData.isHomePage,
      });
      if(editedProductData.details){
        setDetails(editedProductData.details);
      }
      setImagePreview(UserSetting.imgURL + editedProductData.imagePath);
    }
  }, [editedProductData]);

  useEffect(() => {
    if (clearData == false) {
      reset();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  // Insert & Update Data
  const insertProductMaster = (event) => {
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setCkValidated(details == []);
      return;
    } else {
      const form_Data = new FormData();
      form_Data.append("ProductID", formData?.ProductID || 0);
      form_Data.append("ProductName", formData.ProductName);
      form_Data.append("Details", details);
      form_Data.append("IsActive", formData.IsActive);
      form_Data.append("IsHomePage", formData.IsHomePage);
      form_Data.append("ProductCategoryID", formData.ProductCategoryID);
      form_Data.append("image", selectedImage);

      const endpoint =
        formData.ProductID === 0 ? "Product/InsertProduct" : "Product/EditProduct";

      api[formData.ProductID === 0 ? "post" : "patch"](endpoint, form_Data)
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            if (returnID) {
              returnID(result.data.data);
            }
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          reset();
          onClose();
        });
    }
  };

  const reset = () => {
    setFormData(emptyEvent);
    setDetails("")
    setSelectedImage(null);
    setImagePreview(null);
    if (imageInputRef.current) {
      imageInputRef.current.value = null;
    }
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
    // Preview image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <>
      <GlobalFormTagWithSubmit
        validated={validated}
        onSubmit={insertProductMaster}
        refName={ProductFormRef}
        formName="ProductForm"
      >
        <Row>
          <InputGroupWithIcon
            ColSize={8}
            icon="fas fa-building"
            type="text"
            name="ProductName"
            placeholder="Enter Product Name"
            value={formData.ProductName}
            onChange={handleInputChange}
            required
          />
        </Row>
        <Row>
          <GlobalDDComponent
            ColSize={8}
            formLabel="ProductCategory"
            apiEndpoint="ProductCategory/AllProductCategories"
            valueOfDD="productCategoryID"
            labelOfDD="productCategoryName"
            onSelectOption={(productCategoryID) => {
              setFormData((prevData) => ({
                ...prevData,
                ProductCategoryID: productCategoryID,
              }));
              setDDValidationError(false);
            }}
            selectedOptionOfComponent={formData.ProductCategoryID}
            ValidationError={ddValidationError}
            offcanvasTitle="Add ProductCategory"
            offcanvasBodyComponent={<ProductCategoryComponent />}
          />
        </Row>
        <Row>
          <CKEditorInput
            ColSize={8}
            label="Details"
            data={details}
            onChange={setDetails}
            Validation={ckValidated}
          />
        </Row>
       
          <Row>
            <ImageAddAndPreview
              imagePreview={imagePreview}
              onChange={handleImageChange}
              imageInputRef={imageInputRef}
              Label="Image"
            />
          </Row>
          <Row>
          <GlobalCheckbox
            label="Is Active"
            name="IsActive"
            checked={formData.IsActive}
            onChange={handleInputChange}
          />
        </Row>
        <Row>
          <GlobalCheckbox
            label="Is HomePage"
            name="IsHomePage"
            checked={formData.IsHomePage}
            onChange={handleInputChange}
          />
        </Row>
        <SubmitButtonOfForm HeadID={formData?.ProductID} />
      </GlobalFormTagWithSubmit>
    </>
  );
}
