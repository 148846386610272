import React, { useState, useEffect } from "react";
import { Card, Button, Image } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import DoctorComponent from "./DoctorComponent";
import UserSetting from "../../UserSettings.json";
import Loader from "../../Components/Common/Loader ";
import { Edit ,Delete } from "../../Components/Common/EditDelete";

export default function Doctor() {
    const [DoctorData, setDoctorData] = useState([]);
    const [editDoctorData, setEditDoctorData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showDoctorModal, setShowDoctorModal] = useState(false);

    useEffect(() => {
        getDoctor();
      }, []);
    
      // Fetch all Areas
      const getDoctor = () => {
        ;
        setIsLoading(true);
        api
          .get("Doctor/AllDoctors")
          .then((result) => {
            setDoctorData(result.data.data);
            console.log(result.data.data)
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      };
    
      const getSingleCompony = (id) => {
        ;
        api
          .get(`Doctor/SingleDoctor?DoctorID=${id}`)
          .then((result) => {
            const DoctorData = result.data.data;
            console.log(DoctorData)
            setEditDoctorData(DoctorData);
            setShowDoctorModal(true);
          })
          .catch((error) => {
            console.log(error);
          });
      };
    
      // Delete Area
      const DeleteDoctor = (id) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to Delete this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            api
              .patch(`Doctor/DeleteDoctor?DoctorID=${id}`)
              .then((result) => {
                if (result.status === 200) {
                  Swal.fire({
                    icon: "success",
                    title: "Your record has been deleted.",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  getDoctor();
                }
              })
              .catch((error) => {
                console.log(error);
                Swal.fire("Error", "An error occurred.", "error");
              });
          }
        });
      };
    
      const columnDefs = [
        {
            headerName: "Doctor Details", // Header spanning across multiple columns
            cellRenderer: function (params) {
              return (
                <div className="text-start Inter-font">
                  <strong>Doctor Name:</strong> {params.data.doctorName || "N/A"} <hr />
                  <strong>SubMenu Name:</strong> {params.data.subMenuName || "N/A"} <hr />
                  <strong>Details:</strong>{" "}
                  <div
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    dangerouslySetInnerHTML={{ __html: params.data.details }}
                  />
                </div>
              );
            },
            flex: 1,
            minWidth: 400,
            autoHeight: true,
          },

        {
          headerName: "Doctor Image",
          flex: 1,
          minWidth: 150,
          field: "imagePath",
          autoHeight: true,
          cellStyle: { textAlign: "center" },
          headerClass: "center-text widthTableColum ",
          cellRenderer: ({ value }) => (
            <div className="p-2.5">
              <Image style={{width:"50%" ,height:"50%"}} src={UserSetting.imgURL + value}></Image>
            </div>
          ),
        },
        {
            headerName: "Social Media", // Header spanning across multiple columns
            field: "socialMedia", // Assuming you have a field in your data that contains social media information
            cellRenderer: function (params) {
              return (
                <div className="text-start Inter-font">
                  <strong>Instagram:</strong> {params.data.instagram || "N/A"} <hr />
                  <strong>Twitter:</strong> {params.data.twitter || "N/A"} <hr />
                  <strong>Facebook:</strong> {params.data.facebook || "N/A"}
                </div>
              );
            },
            flex: 1,
            minWidth: 300,
            autoHeight : true
          },
          {
            headerName: "DisplayPriority",
            field: "displayPriority",
            headerClass: "widthTableColum",
            sortable: true,
            filter: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            wrapText: true,
            autoHeight: true,
            flex: 1,
            minWidth: 50,
          },
        {
          headerName: "Edit",
          width: 70,
          field: "doctorID",
          cellStyle: { textAlign: "center" },
          headerClass: "center-text widthTableColum",
          cellRenderer: ({ value }) => (
            <button  onClick={() => getSingleCompony(value)}><Edit/></button>
          ),
          pinned: "right",
        },
        
        {
          headerName: "Delete",
          width: 90,
          field: "doctorID",
          cellStyle: { textAlign: "center" },
          headerClass: "center-text widthTableColum",
          cellRenderer: ({ value }) => (
            <button  onClick={() => DeleteDoctor(value)}><Delete/></button>
          ),
          pinned: "right",
        },
      ];

      const HandlModelClose = () => {
        setShowDoctorModal(false);
        setEditDoctorData(null);
      };
  return (
    <>
    {isLoading && <Loader />}
    <ToastContainer />
    <Card.Header className="d-flex justify-content-between">
      <span>Doctor</span>
      <Button
        className="btn-tapti"
        onClick={() => setShowDoctorModal(true)}
      >
        Add Doctor
      </Button>{" "}
    </Card.Header>
    <Card.Body className="withBorder">
      <AgGridComponent
        rowData={DoctorData}
        columnDefs={columnDefs}
        paginationPageSize={7}
      />
    </Card.Body>

    <GlobalModal
      title="New Doctor"
      bodyComponent={
        <DoctorComponent
        onClose={HandlModelClose}
          DoctorID={getDoctor}
          editedDoctorData={editDoctorData}
          clearData = {showDoctorModal}
        />
      }
      isOpen={showDoctorModal}
      onClose={HandlModelClose}
    />
  </>
  )
}
