import React, { useState, useEffect } from "react";
import { Card, Button, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import UserSetting from "../../UserSettings.json";
import { useLoadingContext } from "../../Loading/LoadingContext";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import ProductCategoryComponent from "../ProductCategory/ProductCategoryComponent";
import { Edit, Delete } from "../../Components/Common/EditDelete";
import {
  getAllData,
  getSingleData,
  deleteData,
} from "../../Utils/CallApiUtils";
import {
  createColumnDef,
  createColumnDefButton,
} from "../../Components/Common/AgGridColumnDef";

export default function ProductCategory() {
  const [ProductCategoryData, setProductCategoryData] = useState([]);
  const [editProductCategoryData, setEditProductCategoryData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [showProductCategoryModal, setShowProductCategoryModal] = useState(false);

  useEffect(() => {
    getProductCategory();
  }, []);

  // Fetch all Areas
  const getProductCategory = async () => {
    await getAllData(
      "ProductCategory/AllProductCategories",
      setIsLoading,
      setProductCategoryData
    );
  };

  const getSingleCompony = async (id) => {
    const Result = await getSingleData(
      `ProductCategory/SingleProductCategory?ProductCategoryID=${id}`,
      setEditProductCategoryData,
      setIsLoading
    );
    if (Result) {
      setShowProductCategoryModal(true);
    }
  };

  // Delete Area
  const DeleteProductCategory = async (id) => {
    debugger
    await deleteData(
      `ProductCategory/DeleteProductCategory?productCategoryID=${id}`,
      setIsLoading,
      getProductCategory
    );
  };

  const columnDefs = [
    createColumnDef("ProductCategory Name", "productCategoryName", 150),
    {
      headerName: "Image",
      flex: 1,
      minWidth: 150,
      field: "imagePath",
      autoHeight: true,
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum ",
      cellRenderer: ({ value }) => (
        <div className="p-2.5">
          <Image style={{width:"50%" , height:"50%"}} src={UserSetting.imgURL + value}></Image>
        </div>
      ),
    },
    createColumnDefButton(
      "Edit",
      "productCategoryID",
      (value) => (
        <button onClick={() => getSingleCompony(value)}>
          <Edit />
        </button>
      )
    ),
    createColumnDefButton(
      "Delete",
      "productCategoryID",
      (value) => (
        <button onClick={() => DeleteProductCategory(value)}>
          <Delete />
        </button>
      )
    ),
  ];

  const HandlModelClose = () => {
    setShowProductCategoryModal(false);
    setEditProductCategoryData(null);
  };
  return (
    <>
      <Card.Header className="d-flex justify-content-between">
        <span>ProductCategory</span>
        <Button
          className="btn-tapti"
          onClick={() => setShowProductCategoryModal(true)}
        >
          Add ProductCategory
        </Button>{" "}
      </Card.Header>
      <Card.Body className="withBorder">
        {ProductCategoryData.length > 0 && (
          <AgGridComponent
            rowData={ProductCategoryData}
            columnDefs={columnDefs}
            paginationPageSize={15}
          />
        )}
      </Card.Body>

      <GlobalModal
        title="New ProductCategory"
        bodyComponent={
          <ProductCategoryComponent
            onClose={HandlModelClose}
            returnID={getProductCategory}
            editedProductCategoryData={editProductCategoryData}
            clearData={showProductCategoryModal}
          />
        }
        isOpen={showProductCategoryModal}
        onClose={HandlModelClose}
      />
    </>
  )
}
