import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLoadingContext } from "../../Loading/LoadingContext";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import ProductComponent from "../Product/ProductComponent";
import { Edit, Delete } from "../../Components/Common/EditDelete";
import {
  Col,
  Form,
  InputGroup,
  Button,
  Card,
  Image,
  Row,
} from "react-bootstrap";
import {
  getAllData,
  getSingleData,
  deleteData,
} from "../../Utils/CallApiUtils";
import {
  createColumnDef,
  createColumnDefButton,
} from "../../Components/Common/AgGridColumnDef";
import UserSetting from "../../UserSettings.json";

export default function Product() {
  const [ProductData, setProductData] = useState([]);
  const [editProductData, setEditProductData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [showProductModal, setShowProductModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getProduct();
  }, []);

  // Fetch all Areas
  const getProduct = async () => {
    await getAllData("Product/AllProducts", setIsLoading, setProductData);
    setSearchValue("");
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      await getAllData(
        `Product/SearchProduct?productName=${searchValue}`,
        setIsLoading,
        setProductData
      );
    }
  };

  const getSingleCompony = async (id) => {
    const Result = await getSingleData(
      `Product/SingleProduct?ProductID=${id}`,
      setEditProductData,
      setIsLoading
    );
    if (Result) {
      setShowProductModal(true);
    }
  };

  // Delete Area
  const DeleteProduct = async (id) => {
    debugger;
    await deleteData(
      `Product/DeleteProduct?ProductID=${id}`,
      setIsLoading,
      getProduct
    );
  };

  const columnDefs = [
    {
      headerName: "Product Details",
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>ProductName:</strong> {params.data.productName || "N/A"}{" "}
            <hr />
            <strong>Category:</strong>{" "}
            {params.data.productCategoryName || "N/A"} <hr />
            <strong>Details:</strong>{" "}
            <div
              style={{ whiteSpace: "normal", wordWrap: "break-word" }}
              dangerouslySetInnerHTML={{ __html: params.data.details }}
            />
          </div>
        );
      },
      flex: 1,
      minWidth: 600,
      autoHeight: true,
    },
    {
      headerName: "Image",
      flex: 1,
      minWidth: 150,
      field: "imagePath",
      autoHeight: true,
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum ",
      cellRenderer: ({ value }) => (
        <div className="p-2.5">
          <Image
            style={{ width: "50%", height: "50%" }}
            src={UserSetting.imgURL + value}
          ></Image>
        </div>
      ),
    },
    createColumnDefButton("Edit", "productID", (value) => (
      <button onClick={() => getSingleCompony(value)}>
        <Edit />
      </button>
    )),
    createColumnDefButton("Delete", "productID", (value) => (
      <button onClick={() => DeleteProduct(value)}>
        <Delete />
      </button>
    )),
  ];

  const HandlModelClose = () => {
    setShowProductModal(false);
    setEditProductData(null);
  };
  return (
    <>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <span>Product</span>
        <div className="d-flex align-items-center">
          <Form.Group controlId="formHorizontalEmail" className="mb-2">
            <Col className="d-flex">
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="productName"
                  placeholder="Search Product"
                  value={searchValue}
                  onChange={handleInputChange}
                  onKeyPress={handleSearch}
                  required
                  className="form-control"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter productName.
                </Form.Control.Feedback>
              </InputGroup>
              <Button variant="outline-secondary" onClick={getProduct}>
                <i className="fa fa-refresh"></i>
              </Button>
            </Col>
          </Form.Group>
          <Button
            style={{ height: "50px" }}
            className="btn-tapti ml-2"
            onClick={() => setShowProductModal(true)}
          >
            Add Product
          </Button>
        </div>
      </Card.Header>

      <Card.Body className="withBorder">
        {ProductData.length > 0 && (
          <AgGridComponent
            rowData={ProductData}
            columnDefs={columnDefs}
            paginationPageSize={15}
          />
        )}
      </Card.Body>

      <GlobalModal
        title="New Product"
        bodyComponent={
          <ProductComponent
            onClose={HandlModelClose}
            returnID={getProduct}
            editedProductData={editProductData}
            clearData={showProductModal}
          />
        }
        isOpen={showProductModal}
        onClose={HandlModelClose}
      />
    </>
  );
}
