import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, InputGroup, Button, Image } from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserSetting from "../../UserSettings.json";
import SubMenuComponent from "../SubMenu/SubMenuComponent";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import GlobalDDComponent from "../../Components/Common/GlobalDDComponent";

const emptyDoctor = {
  Doctor_id: 0,
  Doctor_name: "",
  SubMenu_ID: 0,
  DisplayPriority: 0,
  FaceBook: "",
  Twitter: "",
  Instagram: "",
  IsActive: true,
};

export default function DoctorComponent({
  DoctorID,
  editedDoctorData,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyDoctor);
  const DoctorFormRef = useRef(null);
  const imageInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [details, setDetails] = useState("");
  const [ddValidationError, setDDValidationError] = useState(false);

  useEffect(() => {
    ;
    if (clearData == false) {
      reset();
    }
  }, [clearData]);

  useEffect(() => {
    ;
    if (editedDoctorData) {
      setFormData({
        Doctor_id: editedDoctorData.doctorID,
        Doctor_name: editedDoctorData.doctorName,
        SubMenu_ID: editedDoctorData.subMenuID,
        DisplayPriority: editedDoctorData.displayPriority,
        FaceBook: editedDoctorData.facebook,
        Twitter: editedDoctorData.twitter,
        Instagram: editedDoctorData.instagram,
        IsActive: editedDoctorData.isActive,
      });
      setDetails(editedDoctorData.details);
      setImagePreview(UserSetting.imgURL + editedDoctorData.imagePath);
    }
  }, [editedDoctorData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  // Insert & Update Data
  const insertDoctorMaster = (event) => {
    ;
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setDDValidationError(formData.SubMenu_ID === 0);
      event.stopPropagation();
      return;
    } else {
      const form_Data = new FormData();
      form_Data.append("DoctorID", formData?.Doctor_id || 0);
      form_Data.append("SubMenuID", formData.SubMenu_ID);
      form_Data.append("DoctorName", formData.Doctor_name);
      form_Data.append("DisplayPriority", formData.DisplayPriority);
      form_Data.append("IsActive", formData.IsActive);
      form_Data.append("Details", details);
      form_Data.append("Twitter", formData.Twitter);
      form_Data.append("Instagram", formData.Instagram);
      form_Data.append("Facebook", formData.FaceBook);
      form_Data.append("image", selectedImage);

      const endpoint =
        formData.Doctor_id === 0 ? "Doctor/InsertDoctor" : "Doctor/EditDoctor";

      api[formData.Doctor_id === 0 ? "post" : "patch"](endpoint, form_Data)
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            if (DoctorID) {
              DoctorID(result.data.data);
            }
            DoctorID(result.data.data);
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          reset();
          onClose();
        });
    }
  };

  const reset = () => {
    setFormData(emptyDoctor);
    setSelectedImage(null);
    setImagePreview(null);
    if (imageInputRef.current) {
      imageInputRef.current.value = null;
    }
    setDetails("");
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
    // Preview image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };
  return (
    <>
      <Form
        noValidate
        validated={validated}
        // onSubmit={(event) => insertDoctorMaster(event, { resetForm })}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (DoctorFormRef.current && e) {
            // Check for the formName to call insertCity
            if (e.target.name === "DoctorForm") {
              insertDoctorMaster(e);
            }
          }
        }}
        ref={DoctorFormRef}
        name="DoctorForm"
      >
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="Doctor_name">
              <Form.Label>Doctor Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Doctor_name"
                  placeholder="Enter Doctor Name"
                  value={formData.Doctor_name}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Doctor Name.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
          <GlobalDDComponent
          ColSize={8}
          formLabel="Sub Menu"
          apiEndpoint="SubMenu/AllSubMenus"
          valueOfDD="subMenuID"
          labelOfDD="subMenuName"
          onSelectOption={(subMenuID) => {
            setFormData((prevData) => ({
              ...prevData,
              SubMenu_ID: subMenuID,
            }));
            setDDValidationError(false);
          }}
          selectedOptionOfComponent={formData.SubMenu_ID}
          ValidationError={ddValidationError}
          offcanvasTitle="Add SubMenu"
          offcanvasBodyComponent={<SubMenuComponent />}
        />
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="HeaderField">
              <Form.Label>Detail:</Form.Label>
              <InputGroup className="mb-3">
                <div style={{ height: "400px", width: "100%" }}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={details}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDetails(data);
                    }}
                  />
                </div>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={3} lg={3}>
            <Form.Group controlId="Doctor_name">
              <Form.Label>FaceBook:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="FaceBook"
                  placeholder="Enter FaceBook "
                  value={formData.FaceBook}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={3} sm={3} lg={3}>
            <Form.Group controlId="Doctor_name">
              <Form.Label>Instagram:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Instagram"
                  placeholder="Enter Instagram ID"
                  value={formData.Instagram}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col md={3} sm={3} lg={3}>
            <Form.Group controlId="Doctor_name">
              <Form.Label>Twitter:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Twitter"
                  placeholder="Enter Twitter"
                  value={formData.Twitter}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Doctor Logo:</Form.Label>
              <Form.Control
                type="file"
                onChange={handleImageChange}
                ref={imageInputRef}
              />
            </Form.Group>
          </Col>
        </Row>
      { imagePreview && <Row>
          <Col xs={6} md={4}>
            <Image src={imagePreview} thumbnail />
          </Col>
        </Row>}
        <Row>
          <Col md={3} sm={3} lg={3}>
            <Form.Group controlId="Doctor_name">
              <Form.Label>DisplayPriority:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  name="DisplayPriority"
                  placeholder="Enter DisplayPriority"
                  value={formData.DisplayPriority}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="isActive">
              <Form.Label></Form.Label>
              <Form.Check
                type="checkbox"
                label="Is Active"
                name="IsActive"
                checked={formData.IsActive}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="  margin_top10 items-end flex justify-end">
          <Button
            type="submit"
            variant="primary"
            className="mb-20px btn-tapti "
          >
            {formData?.Doctor_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
