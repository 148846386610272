import React, { useState, useEffect } from "react";
import { Card, Button, Image } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import Loader from "../../Components/Common/Loader ";
import { convertDateFormat } from "../../Utils/commonFunction";
import { Edit, Delete } from "../../Components/Common/EditDelete";

export default function Inquiry() {
  const [InquiryData, setInquiryData] = useState([]);
  const [editInquiryData, setEditInquiryData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showInquiryModal, setShowInquiryModal] = useState(false);

  useEffect(() => {
    getInquiry();
  }, []);

  // Fetch all Areas
  const getInquiry = () => {
    setIsLoading(true);
    api
      .get("Inquiry/AllInquiries")
      .then((result) => {
        setInquiryData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSingleInquiry = (id) => {
    api
      .patch(`Inquiry/EditInquirySeenStatus?InquiryID=${id}`)
      .then((result) => {
        toast.success(result.data.message);
        getInquiry();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Area
  const DeleteInquiry = (id) => {
    debugger
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Inquiry/DeleteInquiry?InquiryID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getInquiry();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };
  const columnDefs = [
    {
      headerName: "UserInfo", // Header spanning across multiple columns
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>FirstName:</strong> {params.data.firstName || "N/A"} <hr />
            <strong>LastName:</strong> {params.data.lastName || "N/A"} <hr />
            <strong>CompanyName:</strong> {params.data.companyName || "N/A"} <hr />
            <strong>EmailID:</strong> {params.data.emailID || "N/A"} <hr />
            <strong>MobileNO:</strong> {params.data.mobileNO || "N/A"}{" "}
          </div>
        );
      },
      flex: 1,
      minWidth: 400,
      autoHeight: true,
    },
    {
      headerName: "Inquiries Details ", // Header spanning across multiple columns
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>Inquiry Date:</strong>{" "}
            {convertDateFormat(params.data.createdDate)} <hr />
            <strong>Details:</strong>{" "}
            <div
              style={{ whiteSpace: "normal", wordWrap: "break-word" }}
              dangerouslySetInnerHTML={{ __html: params.data.details }}
            />
          </div>
        );
      },
      flex: 1,
      minWidth: 300,
      autoHeight: true,
    },
    {
      headerName: "Seen Status ", // Header spanning across multiple columns
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            {params.data.seen ? "Seen" : "Unseen"}
            {params.data.seen && (
              <>
                <hr /> <strong>Seen Date:</strong>
              </>
            )}
            {params.data.seen ? convertDateFormat(params.data.seenDate) : ""}
          </div>
        );
      },
      flex: 1,
      minWidth: 300,
      autoHeight: true,
    },
    {
      headerName: "Seen",
      width: 90,
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ data }) => (
        <lord-icon
          src="https://cdn.lordicon.com/ccrgnftl.json"
          trigger="hover"
          style={{ width: "50px", height: "50px" }}
          onClick={() => getSingleInquiry(data.inquiryID)}
          state={data.seen ? undefined : "hover-cross"}
        />
      ),
      pinned: "right",
    },

    {
      headerName: "Delete",
      width: 90,
      field: "inquiryID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button onClick={() => DeleteInquiry(value)}>
          <Delete />
        </button>
      ),
      pinned: "right",
    },
  ];

  const HandlModelClose = () => {
    setShowInquiryModal(false);
    setEditInquiryData(null);
  };

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Card.Header className="d-flex justify-content-between">
        <span>Inquiry</span>
      </Card.Header>
      <Card.Body className="withBorder">
        <AgGridComponent
          rowData={InquiryData}
          columnDefs={columnDefs}
          paginationPageSize={7}
        />
      </Card.Body>
    </>
  );
}
