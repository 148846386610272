// GlobalDDComponent.js
import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import Select from "react-select";
import api from "../../Utils/api";
import GlobalOffcanvas from "../../Components/Common/GlobalOffcanvas";

export default function GlobalDDComponent({
  apiEndpoint,
  formLabel ,
  valueOfDD,
  ColSize,
  labelOfDD,
  onSelectOption,
  selectedOptionOfComponent,
  offcanvasTitle,
  offcanvasBodyComponent,
  ValidationError,
  handleCloseModal,
}) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (ValidationError && selectedOptionOfComponent === 0) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  }, [ValidationError, selectedOptionOfComponent]);

  useEffect(() => {
    if (handleCloseModal) {
      handleCloseOffcanvas();
    }
  }, [handleCloseModal]);

  const fetchData = () => {
    api
      .get(apiEndpoint)
      .then((result) => {
        const list = result.data.data;
        setOptions(list);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    if (selectedOptionOfComponent) {
      const selected = options.find(
        (option) => option[valueOfDD] === selectedOptionOfComponent
      );
      setSelectedOption({
        value: selected?.[valueOfDD],
        label: selected?.[labelOfDD],
      });
    }
  }, [selectedOptionOfComponent, options, valueOfDD, labelOfDD]);

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onSelectOption(selectedOption ? selectedOption.value : null);
  };

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
  };

  const selectDDOption = (ID) => {
    handleOptionChange({ value: ID });
    fetchData();
    handleCloseOffcanvas();
  };

  return (
    <>
      <Col
        md={ColSize ? ColSize : 4}
        sm={ColSize ? ColSize : 4}
        lg={ColSize ? ColSize : 4}
      >
        <Form.Group controlId="MasterMenu_id">
          <Form.Label>{formLabel}:</Form.Label>
          <div className="mb-3 d-flex justify-content-between">
            <div className="selectStartIcon input-group-text" id="basic-addon1">
            <i class="fa fa-bars" aria-hidden="true"></i>
            </div>
            <Select
              className={`w-100 ${validation ? "borderRed" : ""}`}
              options={options.map((option) => ({
                value: option[valueOfDD],
                label: option[labelOfDD],
              }))}
              value={selectedOption}
              onChange={handleOptionChange}
              placeholder={`Select ${formLabel}`}
            />
            <div className="selectEndIcon input-group-text">
              <i
                className="fa fa-plus plusIcon"
                onClick={() => setShowOffcanvas(true)}
              ></i>
            </div>
          </div>
          {validation && (
            <div className="ValidationErrorMsg">Please select a {formLabel}.</div>
          )}
        </Form.Group>
      </Col>

      <GlobalOffcanvas
        title={offcanvasTitle}
        isOpen={showOffcanvas}
        onClose={handleCloseOffcanvas}
        bodyComponent={React.cloneElement(offcanvasBodyComponent, {
          onClose: handleCloseOffcanvas,
          clearData: showOffcanvas,
          returnID: selectDDOption,
        })}
      />
    </>
  );
}


// How to use 
// const MyComponent = () => {
  // const [ddValidationError, setDDValidationError] = useState(false);

// const input function {
//   setDDValidationError(formData.MasterMenuID === 0);
// }


//   return (
  // <GlobalDDComponent
  // ColSize={8}
  // formLabel = "Master Menu"
  //   apiEndpoint="MasterMenu/AllMasterMenus"
  //   valueOfDD="masterMenuID"
  //   labelOfDD="masterMenuName"
  //   onSelectOption={(masterMenuID) => {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       MasterMenuID: masterMenuID,
  //     }));
  //     setDDValidationError(false);
  //   }}
  //   selectedOptionOfComponent={formData.MasterMenuID}
  //   ValidationError={ddValidationError}
  //   offcanvasTitle="Add MasterMenu"
  //   offcanvasBodyComponent={<MasterMenuComponent />}
  // />
//   );
// };

// export default MyComponent;



// export default function MasterMenuComponent({
//   returnID,
//   editedMasterMenuData,
//   clearData,
//   onClose,
// })This Props If requrd in offcanvasBodyComponent