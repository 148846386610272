import React, { useState } from "react";
import "./Header.css";
import { NavLink  } from "react-router-dom";
import { Row } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import CloseButton from "react-bootstrap/CloseButton";
import DashBoard from "../../ImageForSidebar/icons8-dashboard-94.png";
import Menu from "../../ImageForSidebar/icons8-menu-94.png";
import InMenu from "../../ImageForSidebar/icons8-bulleted-list-50.png";
import SMS from "../../ImageForSidebar/icons8-sms-94.png";
import CMS from "../../ImageForSidebar/icons8-client-94.png";
import Company from "../../ImageForSidebar/icons8-company-94.png";
import SliderImage from "../../ImageForSidebar/icons8-gallery-94.png";
import gallery from "../../ImageForSidebar/icons8-pictures-folder-94.png";
import event from "../../ImageForSidebar/icons8-event-48.png";
import videoMaster from "../../ImageForSidebar/icons8-movies-folder-94.png";
import Contact from "../../ImageForSidebar/icons8-contacts-94.png";
import ContactUs from "../../ImageForSidebar/icons8-contact-us-48.png";
import Inquiry from "../../ImageForSidebar/icons8-inquiry-94.png";
import Doctor from "../../ImageForSidebar/icons8-doctor-94.png";
import HomePopUp from "../../ImageForSidebar/icons8-home-94.png";
import Video from "../../ImageForSidebar/icons8-video-94.png";
import SMShistory from "../../ImageForSidebar/icons8-history-94.png";
import SMSApi from "../../ImageForSidebar/icons8-attach-94.png";
import MasterMenu from "../../ImageForSidebar/icons8-menu-50.png";
import ContactImage from "../../ImageForSidebar/icons8-Contact-folder-94.png";
import User from "../../ImageForSidebar/icons8-user-94.png"
import ProductCategory from "../../ImageForSidebar/icons8-package-94.png"
import Product from "../../ImageForSidebar/icons8-commodity-94.png"
import ProductGroup from "../../ImageForSidebar/icons8-trolley-94.png"



export default function Header() {
  const GroupList = [
    { name: "Menu", id: 1, image: Menu },
    { name: "Not", id: 6 },
    // { name: "SMS", id: 2, image: SMS },
    // { name: "Gallery Photo", id: 3, image: gallery },
    // { name: "Video Master", id: 4, image: videoMaster },
    { name: "Product", id: 7, image: ProductGroup },
    // { name: "Contact", id: 5, image: Contact },
  ];

  const FormName = [
    { name: "DashBoard", image: DashBoard, groupId: 6 ,actionName:"DashBoard"},
    { name: "MasterMenu", image: MasterMenu, groupId: 1,actionName:"MasterMenu" },
    { name: "SubMenu", image: InMenu, groupId: 1 ,actionName:"SubMenu"},
    // { name: "SMS Api", image: SMSApi, groupId: 2 ,actionName:"DashBoard"},
    // { name: "SMS History", image: SMShistory, groupId: 2 ,actionName:"DashBoard"},
    // { name: "SMS Signature", image: SMS, groupId: 2,actionName:"DashBoard" },
    { name: "cms", image: CMS, groupId: 6 ,actionName:"CMS"},
    { name: "Company", image: Company, groupId: 6 ,actionName:"Company"},
    { name: "Contact", image: ContactImage, groupId: 6 ,actionName:"Contact"},
    // { name: "Slider Image", image: SliderImage, groupId: 6 ,actionName:"SliderImage"},
    // { name: "Client Logo", image: SliderImage, groupId: 6 ,actionName:"ClientLogo"},
    { name: "Gallery", image: SliderImage, groupId: 3 ,actionName:"Gallery"},
    { name: "Event", image: event, groupId: 3 ,actionName:"Event"},
    { name: "Videos", image: Video, groupId: 4 ,actionName:"Videos"},
    { name: "Video Master", image: videoMaster, groupId: 4 ,actionName:"VideoMaster"},
    // { name: "Contact Us", image: ContactUs, groupId: 5,actionName:"ContactUs" },
    { name: "Inquiry", image: Inquiry, groupId: 6 ,actionName:"Inquiry"},
    { name: "Doctor", image: Doctor, groupId: 6 ,actionName:"Doctor"},
    // { name: "Home PopUp", image: HomePopUp, groupId: 6 ,actionName:"HomePopUp"},
    { name: "User Master", image: User, groupId: 6 ,actionName:"UserMaster"},
    { name: "Product", image: Product, groupId: 7 ,actionName:"Product"},
    { name: "Category", image: ProductCategory, groupId: 7 ,actionName:"ProductCategory"},
  ];

  const [selectedGroup, setSelectedGroup] = useState(null);

  const groupIcons = FormName.reduce((acc, icon) => {
    if (!acc[icon.groupId]) {
      acc[icon.groupId] = [];
    }
    acc[icon.groupId].push(icon);
    return acc;
  }, {});

  const handleGroupClick = (groupId) => {
    setSelectedGroup(selectedGroup === groupId ? null : groupId);
  };

  const closePopover = () => {
    setSelectedGroup(null);
  };

  return (
    <>
      <div className="iconWithNameContainer" style={{ overflowX: "hidden" }}>
        <Row>
          <div  className="icon-list">
            {FormName.filter((icon) => icon.groupId === 6).map((icon, index) => (
              <NavLink 
                className={`icon-item element ${selectedGroup === icon.groupId ? 'selected' : ''}`}
                key={index}
                onClick={closePopover}
                to={icon.actionName}
              >
                
                  <img src={icon.image} alt="" />
                  <span>{icon.name}</span>
               
              </NavLink >
            ))}
            {GroupList.filter((item) => item.id !== 6).map((group, index) => (
              <React.Fragment key={index}>
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  show={selectedGroup === group.id}
                  overlay={
                    <Popover
                      id={`popover-${group.name}`}
                      onClick={closePopover}
                      style={{ boxShadow: "2px 3px 9px -3px rgba(0,0,0,1)" }}
                    >
                      <Popover.Header className="d-flex justify-content-between">
                        <span style={{ fontWeight: "900" }}>{group.name}</span>
                        <CloseButton onClick={closePopover} />
                      </Popover.Header>

                      <Popover.Body
                        style={{
                          maxHeight: "50vh",
                          overflowY: "auto",
                          textAlign: "center",
                        }}
                        className="scrollable-content"
                      >
                        {groupIcons[group.id].map((icon, index) => (
                          <div
                            className={`icon-item element ${selectedGroup === icon.groupId ? 'selected' : ''}`}
                            key={index}
                            style={{ width: "100%" }}
                          >
                            <NavLink  to={icon.actionName}>
                              <img src={icon.image} alt="" />
                              <span>{icon.name}</span>
                            </NavLink >
                          </div>
                        ))}
                      </Popover.Body>
                    </Popover>
                  }
                  onToggle={(show) => {
                    if (!show) {
                      closePopover();
                    }
                  }}
                >
                  <div
                    className={`icon-item element ${selectedGroup === group.id ? 'selected' : ''}`}
                    onClick={() => handleGroupClick(group.id)}
                  >
                    <img src={group.image} alt="" />
                    <span>{group.name}</span>
                    <i className="fa fa-caret-down"></i>
                  </div>
                </OverlayTrigger>
              </React.Fragment>
            ))}
          </div>
        </Row>
      </div>
    </>
  );
}
